import React, {useEffect, useRef, useState} from 'react';
import {useStyles} from './styles/useStyles';
import { MainTheme } from '../../app/src/presenters/theme/CelinePalette';
import {HeadCell} from './data/HeadCell';
import {TableRowData} from './data/TableRowData';
import {DatatableMenuButton, DatatableMenuOptions} from './DatatableMenuButton';
import {AddButtonType, ExportButtonType, ImportButtonType, DatatableToolbar, SearchFeatureType, ToolbarAction} from './DatatableToolbar';
import {ReorderDialog, ReorderingWordingsType} from './dialog/ReorderDialog';
import {ChangeVisibilityColumnDialog, ChangeVisibilityColumnWordingsType} from './dialog/ChangeVisibilityColumnDialog';
import {PaginationOptions} from './configurations/PaginationOption';
import {SortOptions} from './configurations/SortOption';
import {getComparator, Order, stableSort} from './operations/sort';
import {deleteRows} from './operations/delete';
import Paper from '@material-ui/core/Paper';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    Checkbox,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow
} from '@material-ui/core';
import {DatatableHead} from './DatatableHead';
import {DatatablePaginationActions} from './DatatablePaginationActions';
import {MassEditDialog, MassEditBehavior, MassEditOption} from "./dialog/MassEditDialog";
import {ThemeProvider} from "@material-ui/styles";
import { useHistory } from 'react-router-dom';
import Tooltip from "@material-ui/core/Tooltip";
import { useLocation } from 'react-router-dom';

export type ShowHistoryOption = {
    showHistoryButton: boolean,
    urlToViewHistory: string,
    linkToParentPage: boolean,
    icon: string
};

interface DynamicDatatableProps<T extends TableRowData> {
    headCells: HeadCell<T>[];
    data: T[];
    initialOrderBy: keyof T & string;
    densePadding?: boolean;
    showPadding?: boolean;
    editOptions?: DatatableMenuOptions<EditOptionType>[];
    onRowClick?: (row: T) => void;
    onReorderDialogueAction?: (headCells: HeadCell<T>[]) => void;
    stickyHeader?: boolean;
    deactiveSearch?: boolean;
    allowAdding?: AddButtonType;
    allowExporting?: ExportButtonType;
    allowImporting?: ImportButtonType;
    allowReordering?: ReorderingWordingsType;
    allowChangeVisibilityColumn?: ChangeVisibilityColumnWordingsType;
    massEditOptions?: MassEditOption;
    allowSearch?: SearchFeatureType;
    paginationOptions?: PaginationOptions<T>;
    customSortOptions?: SortOptions<T>;
    onChangeVisibilityColumnDialog?: (newList: HeadCell<T>[]) => void;
    showHistory?: ShowHistoryOption[];
    pageTitles?: string[];
    isLoading?: boolean;
    isDownloading?: boolean;
    isUploading?: boolean;
}

export enum EditOptionType {
    Edit,
    Duplicate,
    Delete
}

export const DynamicDatatable = <T extends TableRowData>({
    data,
    headCells,
    initialOrderBy,
    onRowClick,
    onReorderDialogueAction,
    densePadding,
    editOptions,
    stickyHeader,
    deactiveSearch,
    allowAdding,
    allowExporting,
    allowImporting,
    paginationOptions,
    showPadding,
    allowReordering,
    allowChangeVisibilityColumn,
    massEditOptions,
    onChangeVisibilityColumnDialog,
    showHistory,
    pageTitles,
    isLoading,
    isDownloading,
    isUploading,
}: DynamicDatatableProps<T>) => {
    const classes = useStyles();
    const history = useHistory();

    // TODO refacto move pagination concerns (line 54-63) into dedicated module
    const hasCustomPagination = !!paginationOptions;
    const rowsPerPageOption = hasCustomPagination && paginationOptions?.rowsPerPageValues;
    const pagination = {
        total: hasCustomPagination ? paginationOptions!.total : data.length,
        limit: hasCustomPagination ? paginationOptions!.limit : data.length,
        page: hasCustomPagination ? paginationOptions!.page : 1,
        order: hasCustomPagination ? paginationOptions!.order : 'asc',
        orderBy: hasCustomPagination ? paginationOptions!.orderBy : initialOrderBy,
        label: hasCustomPagination && paginationOptions?.rowsPerPageLabel && paginationOptions.rowsPerPageLabel,
        fillWithEmptyRows: (hasCustomPagination && paginationOptions?.meetNbRowsPerPage) || false,
        values: () =>
            rowsPerPageOption && rowsPerPageOption.length
                ? rowsPerPageOption.map((entry) => entry.nb)
                : [10, 25, 50, 100],
        defaultValue: () =>
            rowsPerPageOption && rowsPerPageOption.length
                ? rowsPerPageOption.reduce(
                      (nb, current) => (current.isDefault ? current.nb : nb),
                      rowsPerPageOption[0].nb
                  )
                : pagination.values()[0],
    };

    const tableRowsData = useRef(data);

    const changeVisibilityColumnAction = (onChangeVisibilityColumnDialog!!) ? ((newList: HeadCell<T>[]) => {
        if (onChangeVisibilityColumnDialog) {
            onChangeVisibilityColumnDialog(newList);
        }
        closeChangeVisibilityColumnDialog();
    }) : (() => {closeChangeVisibilityColumnDialog();});

    const reorderDialogueAction = (onReorderDialogueAction!!) ? ((newList: HeadCell<T>[]) => {
        if (onReorderDialogueAction) {
            onReorderDialogueAction(newList);
        }
        closeReorderDialog();
    }) : (() => {closeReorderDialog();});

    useEffect(() => {
        tableRowsData.current = data;
        setRows(tableRowsData.current);
    }, [data]);

    useEffect(() => {
        const handleResize = () => {
            setWindowHeight(window.innerHeight);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const [rows, setRows] = useState<T[]>(tableRowsData.current);

    const [selected, setSelected] = React.useState<string[]>([]);
    const [page, setPage] = React.useState(hasCustomPagination ? paginationOptions!.page - 1 : 0);
    const [order, setOrder] = React.useState<Order>(hasCustomPagination ? paginationOptions!.order : 'asc');
    const [orderBy, setOrderBy] = React.useState<keyof T>(hasCustomPagination ? paginationOptions!.orderBy : initialOrderBy);
    const [dense, setDense] = React.useState(densePadding ?? true);
    const [rowsPerPage, setRowsPerPage] = React.useState(pagination.defaultValue());
    const [changeVisibilityColumnDialogOpened, setChangeVisibilityColumnDialogOpened] = React.useState(false);
    const [reorderDialogOpened, setReorderDialogOpened] = React.useState(false);
    const [massEditDialogOpened, setMassEditDialogOpened] = React.useState(false);
    const [massEditBehavior, setMassEditBehavior] = React.useState<MassEditBehavior>({isEditing : false});
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    const location = useLocation();
    const isAuditUrl = location.pathname.includes('-audit');
    const cellHeight = windowHeight-410;

    const headCellsWithCta = [...headCells];

    if (editOptions) {

        let hasCtaColumn = (headCellsWithCta.filter(headCell => headCell.id === '_cta').length > 0) ? true : false;
        if(!hasCtaColumn) {
            headCellsWithCta.push({ id: '_cta', label: '', numeric: false, disablePadding: true });
        }

    }

    const getRowSize = () => {
        return rows.length;
    }

    const handleSelectAllClick = async (e: React.ChangeEvent<HTMLInputElement>) => {

        if (e.target.checked && massEditOptions) {

            const selectedRows = await massEditOptions.onSelectAllClick();
            if(selectedRows) {
                let rows: any = selectedRows.map((n) => n);
                setSelected(rows);
            }
            return;
        }

        setSelected([]);
    };

    const handleClick = (e: React.MouseEvent<unknown>, row: any) => {
        const name = row.id;
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        onRowClick && onRowClick(row);
        massEditBehavior.isEditing && setSelected(newSelected);
    };

    const handleRequestSort = async (e: React.MouseEvent<unknown>, property: any) => {

            const isAsc = orderBy === property && order === 'asc';
            let newOrder: Order = isAsc ? 'desc' : 'asc';
            if (paginationOptions!.onHandleSortChange) {
                await paginationOptions!.onHandleSortChange(newOrder, property);
            }

            setOrder(newOrder);
            setOrderBy(property);
    };

    const handleChangeRowsPerPage = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const newRowsPerPage = parseInt(e.target.value, 10);
        setPage(0);
        setRowsPerPage(newRowsPerPage);

        if (paginationOptions!.onPaginationChange) {
            await paginationOptions!.onPaginationChange(1, newRowsPerPage);
        }
    };

    const handlePaginationChange = async (e: unknown, newPage: number) => {
        setPage(newPage);

        if (paginationOptions!.onPaginationChange) {
            await paginationOptions!.onPaginationChange(newPage + 1, rowsPerPage);
        }
    };

    const handleMultiCellsSearch = async (newList: HeadCell<T>[]) => {
        setPage(0);
        if (paginationOptions!.onMultiCellsSearch) {
            await paginationOptions!.onMultiCellsSearch(newList);
        }
    }

    const handleChangeDense = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDense(e.target.checked);
    };

    const isSelected = (name: string) => {
        return selected.indexOf(name) !== -1;
    }

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    function showHistoryAction<T extends TableRowData>(props: DynamicDatatableProps<T>): ShowHistoryOption[] {
        if (props.showHistory === undefined) {
            return [{ urlToViewHistory: '', showHistoryButton: false, linkToParentPage: false, icon: 'HistoryIcon' }];
        }
        return props.showHistory;
    }

    const handleToolbarAction = (action: ToolbarAction) => {
        switch (action) {
            case ToolbarAction.Delete:
                const rowsAfterDeletion = deleteRows(tableRowsData.current, selected, 'id');
                tableRowsData.current = rowsAfterDeletion;
                setSelected([]);
                break;
            case ToolbarAction.Add:
                allowAdding?.onClick();
                break;
            case ToolbarAction.Export:
                allowExporting?.onClick();
                break;
            case ToolbarAction.Import:
                allowImporting?.onClick();
                break;
            case ToolbarAction.ShowHistory:
                showHistoryAction(showHistory);
                break;
            case ToolbarAction.ChangeVisibilityCols:
                setChangeVisibilityColumnDialogOpened(true);
                break;
            case ToolbarAction.ReorderCols:
                setReorderDialogOpened(true);
                break;
            case ToolbarAction.OpenMassEditMode:
                setMassEditBehavior({isEditing : true});
                break;
            case ToolbarAction.CloseMassEditMode:
                setMassEditBehavior({isEditing : false});
                setSelected([]);
                setMassEditDialogOpened(false);
                break;
            case ToolbarAction.MassEdit:
                setMassEditDialogOpened(true);
                break;
        }
    };

    function closeChangeVisibilityColumnDialog() {
        setChangeVisibilityColumnDialogOpened(false);
    }

    function closeReorderDialog() {
        setReorderDialogOpened(false);
    }

    function closeMassEditDialog() {
        setMassEditDialogOpened(false);
    }

    return (
        <div className={classes.root}>
            <ThemeProvider theme={MainTheme}>
                <Paper className={classes.paper}>
                    { (allowAdding || allowExporting || allowImporting || allowChangeVisibilityColumn || allowReordering) && (
                        <DatatableToolbar
                            enableAddButton={allowAdding}
                            enableExportButton={allowExporting}
                            isDownloading={isDownloading || false}
                            enableImportButton={allowImporting}
                            isUploading={isUploading || false}
                            numSelected={selected.length}
                            onAction={handleToolbarAction}
                            enableChangeVisibilityColumn={allowChangeVisibilityColumn}
                            enableReordering={allowReordering}
                            allowMassEdit={massEditOptions}
                            isMassEditActivated={massEditBehavior.isEditing}
                            showHistory={showHistory}
                            pageTitles={pageTitles}
                        />
                    )}
                    <TableContainer className={clsx({ [classes.container]: stickyHeader })}>

                        <Table stickyHeader={stickyHeader} aria-label="sticky table" size={dense ? 'small' : 'medium'}>
                            <DatatableHead
                                headCells={headCellsWithCta}
                                classes={classes}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                enableRowSelection={!!massEditBehavior.isEditing}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                onMultiCellsSearch={handleMultiCellsSearch}
                                rowCount={pagination.total}
                                deactiveSearch={!!deactiveSearch}
                            />
                            <TableBody>
                                {(isLoading) && (
                                    <TableRow style={{whiteSpace: isAuditUrl ? 'nowrap' : 'normal'}}>
                                        <TableCell colSpan={headCellsWithCta.length} align={'center'} style={{ height: `${cellHeight}px` }} >
                                            <CircularProgress style={{ color: '#a1a1a1' }}/>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {(!isLoading && getRowSize() < 1) && (
                                    <TableRow style={{whiteSpace: isAuditUrl ? 'nowrap' : 'normal'}}>
                                        <TableCell colSpan={headCellsWithCta.length} align={'center'} >No results to show</TableCell>
                                    </TableRow>
                                )}
                                {(!isLoading && getRowSize() > 0) && (
                                    stableSort(rows, getComparator(order, orderBy)).map((row: T, index: number) => {
                                        if(headCells.filter(headCell => headCell.visible).length < 1) return;

                                        const isItemSelected = isSelected(row.id);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                hover
                                                style={{ cursor: onRowClick ? 'pointer' : 'default', whiteSpace: isAuditUrl ? 'nowrap' : 'normal'}}
                                                onClick={(e) => handleClick(e, row)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isItemSelected}
                                            >
                                                {!!massEditBehavior.isEditing && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    </TableCell>
                                                )}

                                                {headCellsWithCta.filter(headCell => (headCell.visible)).map((cellItem, cellIndex) => {
                                                    const cellKey = `${row.id}__${cellItem.id}`;
                                                    const cellMod: string = `${cellItem.id}Mod`;
                                                    const cellPrevious = `previous${cellItem.id.charAt(0).toUpperCase()}${cellItem.id.slice(1)}`;
                                                    const rowItem: boolean = row[cellMod]; //get Mod based on cellItemId (ex: clickAndCollect => clickAndCollectMod)

                                                    const title: string = row[cellPrevious] !== null
                                                        ? row[cellPrevious] !== 0
                                                            ? String(row[cellPrevious])
                                                            : "0"
                                                        : "-";

                                                    return massEditBehavior.isEditing && cellIndex === 0 ? (
                                                        <TableCell
                                                            key={cellKey}
                                                            id={labelId}
                                                            component="th"
                                                            scope="row"
                                                            padding="none"
                                                        >
                                                            {rowItem && <Tooltip size="large" title={title}><b>{row[cellItem.id]}</b></Tooltip>}
                                                            {!rowItem && row[cellItem.id]}
                                                        </TableCell>
                                                    ) : (
                                                        <TableCell key={cellKey}>
                                                            {rowItem && (
                                                                <Tooltip size="large" title={title}>
                                                                    <div className="date-container">
                                                                        {String(row[cellItem.id]).split(';').map((date, index) => (
                                                                            <p><b key={index}>{date.trim()}</b></p>
                                                                        ))}
                                                                    </div>
                                                                </Tooltip>
                                                            )}
                                                            {!rowItem && (
                                                                <div className="date-container" style={{ display: 'block', whiteSpace: 'pre-wrap', overflowWrap: 'anywhere' }}>
                                                                    {String(row[cellItem.id]).split(';').reduce((result, date, index, array) => {
                                                                        if (index % 10 === 0 && index !== 0) {
                                                                            result.push(<br key={`br-${index}`} />);
                                                                        }
                                                                        result.push(
                                                                            <span key={index}>{date.trim()}{index !== array.length - 1 && ';'}</span>
                                                                        );
                                                                        return result;
                                                                    }, [])}
                                                                </div>
                                                            )}
                                                        </TableCell>
                                                    );
                                                })}
                                                {editOptions && (
                                                    <TableCell align="right">
                                                        <DatatableMenuButton data={row} options={editOptions} />
                                                    </TableCell>
                                                )}
                                            </TableRow>
                                        );
                                    }))}
                                    {pagination.fillWithEmptyRows && emptyRows > 0 && (
                                        <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { paginationOptions && (
                        <TablePagination
                            rowsPerPageOptions={pagination.values()}
                            labelRowsPerPage={pagination.label}
                            component="div"
                            count={pagination.total}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handlePaginationChange}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={DatatablePaginationActions}
                        />
                    )}
                </Paper>

                {reorderDialogOpened && (
                    <ReorderDialog
                        closeReorderDialog={closeReorderDialog}
                        wordings={allowReordering!!}
                        headCells={headCells}
                        onReorderDialog={reorderDialogueAction}
                    />
                )}
                {changeVisibilityColumnDialogOpened && (
                    <ChangeVisibilityColumnDialog
                        changeVisibilityColumnDialog={closeChangeVisibilityColumnDialog}
                        wordings={allowChangeVisibilityColumn!!}
                        headCells={headCells}
                        onChangeVisibilityColumnDialog={changeVisibilityColumnAction}
                    />
                )}
                {massEditDialogOpened && (
                    <MassEditDialog
                        objectDefinition={headCellsWithCta}
                        closeDialog={closeMassEditDialog}
                        massEditOptions={massEditOptions!!}
                        selected={selected}
                    />
                )}
                {showPadding
                  ? <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" /> : ''
                }
            </ThemeProvider>
        </div>
    );
};
