import React, {useContext, useEffect, useState} from 'react';
import {Page} from '../../presenters/pages/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import {AppContext, AppContextSpec, useAsyncEffect} from '../../App';
import {strings} from '../../i18n/strings';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import {
    AsyncAutocompleteSelectComponent,
    DropDownValue,
    SupportedReference
} from '../../domain/components/AsyncAutocompleteSelect';
import {grey} from '@material-ui/core/colors';
import {UIButton} from '../../../../ui/inputs/UIButton';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import {useOktaAuth} from '../../../../okta-react';
import {exportDataToExcel, getData} from "../../domain/repository/DatasetRequestRepository";
import {HeadCell} from "../../../../graphics/tables/data/HeadCell";
import {DatasetStructure} from "../../domain/indexes/DatasetIndexes";
import {DynamicDatatable} from "../../../../graphics/tables/DynamicDatatable";
import {Order} from "../../../../graphics/tables/operations/sort";
import {RecordRepresentation} from "./CountryDaysOffPage";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootFilter: {
            display: 'flex',
            flexDirection: 'row',
            '& > *': {
                marginBottom: '1em',
                marginRight: '1em'
            }
        },
        filterPresentation: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            textAlign: 'left'
        },
        cfoStockPresentation: {
            textAlign: 'left'
        },
        waitingSelection: {
            color: grey[600],
            borderRadius: '4px',
            borderColor: grey[600],
            border: 'solid 1px'
        },
        saveAction: {
            margin: '15px 0 21px 0',
            width: '150px',
            marginLeft: 'auto'
        },
        filterItem: {
            display: 'flex',
            marginBottom: '1em',
            marginRight: '1em',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        marginTop20: {
            marginTop: '20px',
            color: grey[600],
            borderRadius: '4px',
            borderColor: grey[600],
            border: 'solid 1px'
        },
        positiveStock: {
            color: grey[500],
        },
        negativeStock: {},
    })
);

const wordingDataSetRequest = strings.page.dataSetRequest;

export const DataSetRequestPage = () => {

    const {authState} = useOktaAuth();
    const classes = useStyles();
    const [requestSelection, setRequestSelection] = React.useState(String);
    const [requestChangeSelection, setRequestChangeSelection] = useState<DropDownValue | null | undefined>(null);//used in his child AsyncAutoCompleteSelect, used to disable the Submit button
    const appContext = useContext<AppContextSpec>(AppContext);
    const [downloading, setDownloading] = useState(false);
    const [pagination, setPagination] = useState<DatasetStructure.Pagination>();
    const [headCellsConfig, setHeadCellsConfig] = useState<HeadCell<any>[]>([]);
    const wordingsDatatable = strings.datatable;
    const [limit, setLimit] = useState(20);
    const [page, setPage] = useState(1);
    const [order, setOrder] = useState<Order>('asc');
    const [orderBy, setOrderBy] = useState<string>('');
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [requestId, setRequestId] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [tableKey, setTableKey] = useState(0);

    useAsyncEffect(async () => {
            await getAndRefreshRecords();
        }, [page, limit]
    );

    useEffect(() => {
        setPage(1);
        setLimit(20);
    }, []);

    const getAndRefreshRecords = async () => {
        if (!!requestId) {
            const response: DatasetStructure.DynamicResponse<any> = await getData<any>(authState!, requestId, [], page, limit, order, orderBy);
            setPagination(response.pagination);
            setRowData(response.values[0]);
            if (page !== response.pagination.page) {
                setPage(response.pagination.page);
            }
        }
    }

    const onRequestSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newRequestValue = e.target.value.trim();
        if (newRequestValue.length > 0 && newRequestValue !== requestSelection) {
            newRequestValue = newRequestValue.split(" ")[0].trim();
            setRequestSelection(newRequestValue);
        }
    };

    const downloadExcel = async (id: string) => {
        setDownloading(true);
        try {
            const response = await exportDataToExcel(authState!, id);

            if (response instanceof ArrayBuffer) {
                const blob = new Blob([response], {type: 'application/octet-stream'});
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'data_set.xlsx');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            } else {
                appContext.notifyError(response.message)
            }
        } catch (error: any) {
            appContext.notifyError(error.message)
        } finally {
            setDownloading(false);
        }
    };

    const handleRequestChangeSelection = async (value: DropDownValue | null | undefined) => {
        const initialPage = 1;
        const initialLimit = 20;

        setPage(initialPage);  // Set page to 1
        setLimit(initialLimit); // Set limit to default
        setLoading(true);


        try {
            setRequestChangeSelection(value);
            if (value) {
                setRequestId(value.key);
                const response: DatasetStructure.DynamicResponse<any> = await getData<any>(authState!, value.key, [], 1, 20, order, orderBy);

                const headCells: HeadCell<any>[] = response.headers.map((field, index) => {
                    const firstRow = response?.values[0][0];
                    const fieldNameFromRow = firstRow ? Object.keys(firstRow)[index] : field;

                    return {
                        id: fieldNameFromRow,
                        label: fieldNameFromRow,
                        displayName: field.toUpperCase(),
                        type: "",
                        required: true,
                        information: "",
                        position: 1,
                        visible: true,
                        editable: false,
                        massEditable: false,
                        reference: "",
                        referenceColumnLabel: "",
                        disablePadding: false,
                        searchValue: "",
                    };
                });
                const updatedLimit = response.values[0].length > initialLimit ? response.values[0].length : initialLimit;
                setPage(1);
                setLimit(updatedLimit);
                setTableKey(prevKey => prevKey + 1);
                setHeadCellsConfig(headCells);
                setPagination(response.pagination);
                setRowData(response.values[0]);
            }
        } catch (error: any) {
            resetTable();
            appContext.notifyError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const resetTable = () => {
        setHeadCellsConfig([]);
        setRowData([]);
    };

    const handlePaginationChange = (page: number, limit: number) => {
        setPage(page);
        setLimit(limit);
    };

    const handleSortChange = async (order: Order, orderBy: string) => {
        setOrder(order);
        setOrderBy(orderBy);
    };

    const handleMultiCellsSearch = (newList: HeadCell<RecordRepresentation>[]) => {
        headCellsConfig.forEach((cell: HeadCell<RecordRepresentation>) => {
            cell.searchValue = newList.filter(newListCell => newListCell.id === cell.id)[0].searchValue;
        });
    };


    return (
        <Page title={wordingDataSetRequest.title} descriptionLine1={wordingDataSetRequest.description.line1}
              descriptionLine2={wordingDataSetRequest.description.line2}>
            <div className={classes.rootFilter}>
                <div className={classes.buttonContainer}>

                    <div className={classes.filterItem}>
                        <AsyncAutocompleteSelectComponent
                            id={`edit-data-set-request`}
                            key={`key-dataset`}
                            reference={SupportedReference.dataset}
                            required={false}
                            information={""}
                            autocompleteDropDownStyle={{'width': '75vw'}}
                            label={"Dataset Label"}
                            onSelect={onRequestSelection}
                            defaultValue={requestChangeSelection}
                            onChange={handleRequestChangeSelection}
                        />
                    </div>
                    <UIButton
                        text={downloading ? <CircularProgress size={20}/> : wordingDataSetRequest.action.export.title}
                        color="primary"
                        className={`${classes.saveAction}`}
                        onClick={() => {
                            const requestId = requestChangeSelection?.key;
                            if (requestId) {
                                downloadExcel(requestId);
                            }
                        }}
                        disabled={requestChangeSelection === null || downloading}
                    />
                </div>
            </div>
                <DynamicDatatable
                    key={tableKey}
                    stickyHeader={true}
                    deactiveSearch={true}
                    headCells={headCellsConfig}
                    data={rowData}
                    initialOrderBy={'id'}
                    showPadding={false}
                    isLoading={loading}
                    paginationOptions={(() => ({
                        page,
                        limit,
                        order,
                        total: pagination?.total || 0,
                        orderBy,
                        rowsPerPageValues: [
                            {nb: limit, isDefault: true},
                        ],
                        meetNbRowsPerPage: false,
                        rowsPerPageLabel: wordingsDatatable.pagination.rowsPerPage,
                        onPaginationChange: handlePaginationChange,
                        onHandleSortChange: handleSortChange,
                        onMultiCellsSearch: handleMultiCellsSearch
                    }))()}
                />
        </Page>
    );

};
