import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { StockLocationChannelStoreTableStructure } from '../indexes/StockLocationChannelStoreIndexes';
import { ChannelTableStructure } from '../indexes/ChannelIndexes';
import { StoreTableStructure } from '../indexes/StoreIndexes';
import { AuthState } from '@okta/okta-auth-js';
import { buildHeader } from './utils/Utils';
import {Order} from "../../../../graphics/tables/operations/sort";
import {StockLocationTableStructure} from "../indexes/StockLocationIndexes";
import {EndpointStockLocation} from "./StockLocationRepository";

export const getStockLocationChannelStoreRecords = <T>(
    authState: AuthState,
    channelCode: string,
    storeCode: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationChannelStoreTableStructure.ResponseOf<T>> => {
    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }

    return datasource.remote
        .getData<StockLocationChannelStoreTableStructure.ResponseOf<T>>(
            uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.getStockLocationChannelStoreRecords), {}),
            buildHeader(authState), params)
        .then(unwrap);
};

export const getSlcsStockLocation = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<StockLocationTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.getStockLocation), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const getSlcsChannel = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ChannelTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<ChannelTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.getChannel), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const getSlcsStore = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StoreTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<StoreTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.getStore), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const editStockLocationChannelStoreRecords = (
    authState: AuthState,
    stockLocationChannelStoresToUpdate: StockLocationChannelStoreTableStructure.StockLocationChannelStoreRanking[],
    channelCode: string,
    storeCode: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => {
    const params = new Map();

    if (channelCode) {
        params.set('channelCode', channelCode);
    }
    if (storeCode) {
        params.set('storeCode', storeCode);
    }

    const body = {
        stockLocationChannelStores : stockLocationChannelStoresToUpdate
    }

    return datasource.remote
        .putData<void, StockLocationChannelStoreTableStructure.StockLocationChannelstoreRankings>(
            uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.updateStockLocationChannelStoreRecords), {}), body, buildHeader(authState), params)
        .then(() => {});
};

export const searchStockLocationChannelStoreRecords = <T>(
    authState: AuthState,
    search: StockLocationTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockLocationTableStructure.ResponseOf<T>, StockLocationTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockLocationChannelStore.endPoint.concat(EndpointStockLocationChannelStore.search), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointStockLocationChannelStore = {
    endPoint: '/end-point',
    getStockLocationChannelStoreRecords: '/stock-location-channel-store',
    getChannel: '/stock-location-channel-store/channel',
    getStore: '/stock-location-channel-store/store',
    getStockLocation: '/stock-location-channel-store/stock-location',
    updateStockLocationChannelStoreRecords : '/stock-location-channel-store',
    search: '/stock-location-channel-store/search'
};