import { ApiDatasource } from '../../adapters/io/datasources/ApiDatasource';
import { unwrap } from '../../adapters/io/network/HttpResponse';
import { formatText } from '../../adapters/io/network/utils';
import { ApiDatasourceSpec } from '../../core/datasource';
import { StockLocationTableStructure } from '../indexes/StockLocationIndexes';
import { AuthState } from '@okta/okta-auth-js';
import { Order } from '../../../../graphics/tables/operations/sort';
import { buildHeader } from './utils/Utils';

export const getStockLocation = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<StockLocationTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.getStockLocation), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const getStockLocationDepot = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {

    const params = new Map();

    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .getData<StockLocationTableStructure.ResponseOf<T>>(uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.getStockLocationDepot), {}), buildHeader(authState), params)
        .then(unwrap);
};


export const searchStockLocation = <T>(
    authState: AuthState,
    search: StockLocationTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockLocationTableStructure.ResponseOf<T>, StockLocationTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.searchStockLocationRecords), {}), 
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const searchStockLocationStartingWith = <T>(
    authState: AuthState,
    search: StockLocationTableStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOf<T>> => {
    const params = new Map();
    const body = {
        fieldSearches : search
    }
    page !== undefined && params.set('page', page);
    limit !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<StockLocationTableStructure.ResponseOf<T>, StockLocationTableStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.searchStockLocationRecordsStartingWith), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const editStockLocationRecord = <RecordType>(
    authState: AuthState,
    stockLocationId: string,
    record: RecordType,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> => 
    datasource.remote
        .putData<void, RecordType>(uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.editStockLocation), {}) + `?stockLocationId=${stockLocationId}`, record, buildHeader(authState), new Map())
        .then(() => {});

export const massEditStockLocation = <MassEditRequest>(
    authState: AuthState,
    massEditRequest: MassEditRequest,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<void> =>
    datasource.remote
        .putData<void, MassEditRequest>(uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.massEditStockLocation), {}), massEditRequest, buildHeader(authState), new Map())
        .then(() => {});

export const getStockLocationColumnInformation = <T>(
    authState: AuthState,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<StockLocationTableStructure.ResponseOfColumn<T>> => {
    const params = new Map();

    return datasource.remote
        .getData<StockLocationTableStructure.ResponseOfColumn<T>>(
            uriFormatter(EndpointStockLocation.endPoint.concat(EndpointStockLocation.getStockLocationColumnInformation), {}),
            buildHeader(authState), params)
        .then(unwrap);
};


export const EndpointStockLocation = {
    endPoint: '/end-point',
    getStockLocation: '/stock-location',
    getStockLocationDepot: '/stock-location/depot',
    searchStockLocationRecords: '/stock-location/search',
    searchStockLocationRecordsStartingWith: '/stock-location/search-by-code-starting-with',
    editStockLocation: '/stock-location',
    massEditStockLocation:'/stock-location/mass-edit',
    getStockLocationColumnInformation: '/stock-location/column-information'
};