import React from 'react';
import { Button, ButtonProps } from '@material-ui/core';
import { MainTheme } from '../theme/CelinePalette';
import { ThemeProvider } from '@material-ui/styles';

type UIButtonProps = {
    text: string | React.ReactNode;
};

export const UIButton = ({ text, ...props }: UIButtonProps & ButtonProps) => (
    <ThemeProvider theme={MainTheme}>
        <Button variant="contained" {...props}>
            {text}
        </Button>
    </ThemeProvider>
);
