import {ApiDatasource} from '../../adapters/io/datasources/ApiDatasource';
import {unwrap} from '../../adapters/io/network/HttpResponse';
import {formatText} from '../../adapters/io/network/utils';
import {ApiDatasourceSpec} from '../../core/datasource';
import {AuthState} from '@okta/okta-auth-js';
import {buildExcelHeader, buildHeader} from './utils/Utils';
import {DatasetLabelTableStructure} from "../indexes/DatasetLabelIndexes";
import {DatasetStructure} from "../indexes/DatasetIndexes";
import {Order} from "../../../../graphics/tables/operations/sort";
import {StockLocationTableStructure} from "../indexes/StockLocationIndexes";

export const getDatasetLabels = <T>(
    authState: AuthState,
    page?: number,
    limit?: number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DatasetLabelTableStructure.ResponseOf<T>> => {

    const params = new Map();

    if (page) {
        params.set('page', page);
        params.set('limit', limit);
    }

    return datasource.remote
        .getData<DatasetLabelTableStructure.ResponseOf<T>>(uriFormatter(EndpointDataset.endPoint.concat(EndpointDataset.getDatasetLabels), {}), buildHeader(authState), params)
        .then(unwrap);
};

export const exportDataToExcel = (
    authState: AuthState,
    id: string,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<ArrayBuffer | Error> => {
    const params = new Map();

    id !== undefined && params.set('id', id);
    return datasource.remote
        .getDataExcel(
            uriFormatter(EndpointDataset.endPoint.concat(EndpointDataset.exportDataToExcel), {}),
            {}, buildExcelHeader(authState), params
        )
        .then(response => {
            return response;
        });
};

export const getData = <T>(
    authState: AuthState,
    id: string,
    search: DatasetStructure.FormattedSearchValue[],
    page?: number,
    limit?: number,
    order?: Order,
    orderBy?: string | number,
    datasource: ApiDatasourceSpec = new ApiDatasource(),
    uriFormatter: (uri: string, ...params: unknown[]) => string = formatText
): Promise<DatasetStructure.DynamicResponse<T>> => {

    const params = new Map();
    params.set('id', id);
    const body = {
        fieldSearches : search
    }
    order !== undefined && params.set('page', page);
    order !== undefined && params.set('limit', limit);
    order !== undefined && params.set('order', order);
    orderBy !== undefined && params.set('orderBy', orderBy);

    return datasource.remote
        .postData<DatasetStructure.DynamicResponse<T>, DatasetStructure.ListOfFormattedSearchValue>(
            uriFormatter(EndpointDataset.endPoint.concat(EndpointDataset.exportDataTable), {}),
            body, buildHeader(authState), params)
        .then(unwrap);
};

export const EndpointDataset = {
    endPoint: '/end-point',
    getDatasetLabels: '/data-set',
    exportDataToExcel: '/data-set/export-excel',
    exportDataTable: '/data-set/export-data'
};