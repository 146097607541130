import React, { useContext, useState } from 'react';
import { Page } from '../../presenters/pages/index';
import { AppContext, AppContextSpec } from '../../App';
import { strings } from '../../i18n/strings';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AsyncAutocompleteSelectComponent, SupportedReference } from '../../domain/components/AsyncAutocompleteSelect';
import { grey } from '@material-ui/core/colors';
import { StockLocationChannelStoreTableStructure } from '../../domain/indexes/StockLocationChannelStoreIndexes';
import { getStockLocationChannelStoreRecords, editStockLocationChannelStoreRecords } from '../../domain/repository/StockLocationChannelStoreRepository';
import { UIButton } from '../../../../ui/inputs/UIButton';
import { TableRowData } from '../../../../graphics/tables/data/TableRowData';
import { AgGridReact, AgGridColumn } from '@ag-grid-community/react';
import { AllCommunityModules, GridReadyEvent, GridApi, ColumnApi, RowNode } from '@ag-grid-community/all-modules';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { useOktaAuth } from '../../../../okta-react';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        rootFilter: {
            display: 'flex',
            flexDirection: 'row',
            '& > *': {
                marginBottom: '1em',
                marginRight: '1em'
            }
        },
        filterPresentation: {
            textDecoration: 'underline',
            fontWeight: 'bold',
            textAlign: 'left'
        },
        waitingSelection: {
            color: grey[600],
            borderRadius: '4px',
            borderColor: grey[600],
            border: 'solid 1px'
        },
        saveAction: {
            margin: '15px 0 21px 0',
            marginLeft: 'auto'
        }
    })
);

const wordingStockLocationRanking = strings.page.stockLocationRanking;

interface RecordRepresentation extends Record, TableRowData { };

type TableStockLocationChannelStore = StockLocationChannelStoreTableStructure.StockLocationChannelStore;
type Record = StockLocationChannelStoreTableStructure.RecordOf<TableStockLocationChannelStore>;

export const StockLocationRankingPage = () => {

    const { authState } = useOktaAuth();
    const classes = useStyles();
    const [channelSelection, setChannelSelection] = React.useState(String);
    const [storeSelection, setStoreSelection] = React.useState(String);
    const [rowData, setRowData] = useState<RecordRepresentation[]>([]);
    const [tableData, setTableData] = useState<StockLocationChannelStoreTableStructure.ResponseOf<TableStockLocationChannelStore>>();
    const appContext = useContext<AppContextSpec>(AppContext);
    const [gridApi, setGridApi] = useState<GridApi>();
    // eslint-disable-next-line
    const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>();
    const [rowPositionHasChange, setRowPositionHasChange ]= React.useState(false);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);

        params.api.sizeColumnsToFit();
    };

    const onDragEnd = () => {
        setRowPositionHasChange(true);
    }

    const saveRowPosition = (e: any) => {
        let priorityCounter = 0;
        let stockLocationChannelStoresToUpdate: StockLocationChannelStoreTableStructure.StockLocationChannelStoreRanking[] = [];
        gridApi?.forEachLeafNode((rowNode: RowNode) => {

            priorityCounter += 10;
            rowNode.data.rankIfEqualQuantity = priorityCounter;

            let stockLocationChannelStoreRanking: StockLocationChannelStoreTableStructure.StockLocationChannelStoreRanking = {
                id : rowNode.data.id,
                rankIfEqualQuantity : rowNode.data.rankIfEqualQuantity
            }

            stockLocationChannelStoresToUpdate.push(stockLocationChannelStoreRanking);
        });

        editStockLocationChannelStoreRecords(authState!, stockLocationChannelStoresToUpdate, channelSelection, storeSelection)
            .then((() => {
                appContext.notifySuccess(wordingStockLocationRanking.action.save.confirmationFeedbackMessage)
            }))
            .catch(({ message }) => {
                console.log(message)
                message && appContext.notifyError(
                    message.includes('permission') ? message :
                    wordingStockLocationRanking.action.save.errorFeedbackMessage
                );
                return [];
            })
            .finally(() => appContext.displayLoader(false));;

        setRowPositionHasChange(false);
    }


    const getRecords = (channelValue: string, storeValue: string) => {

        const updateState = (data: StockLocationChannelStoreTableStructure.ResponseOf<TableStockLocationChannelStore>) => {
            setTableData(data);
        }

        return getStockLocationChannelStoreRecords<TableStockLocationChannelStore>(authState!, channelValue, storeValue).then((data) => {
            updateState(data);
            return data.values;
        });

    };

    const getFilteredRecords = (channelValue: string, storeValue: string) => {
        if (channelValue && storeValue) {

            return getRecords(channelValue, storeValue).then(setRowData)
                .catch(({ message }) => {
                    message && appContext.notifyError(message);
                    return [];
                })
                .finally(() => appContext.displayLoader(false));
        }
    };

    const onChannelSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newChannelValue = e.target.value;
        if(newChannelValue.length > 0 && newChannelValue !== channelSelection) {
            newChannelValue = newChannelValue.split(" ")[0].trim();
            setChannelSelection(newChannelValue);
            getFilteredRecords(newChannelValue, storeSelection);
        }
    };

    const onStoreSelection = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>) => {
        let newStoreValue = e.target.value;
        if(newStoreValue.length > 0 && newStoreValue !== storeSelection) {
            newStoreValue = newStoreValue.split(" ")[0].trim();
            setStoreSelection(newStoreValue);
            getFilteredRecords(channelSelection, newStoreValue);
        }
    };


    return (
        <Page title={wordingStockLocationRanking.title} descriptionLine1={wordingStockLocationRanking.description.line1} descriptionLine2={wordingStockLocationRanking.description.line2}>
            <p className={classes.filterPresentation}>StockPool Filter</p>
            <div className={classes.rootFilter}>
                <AsyncAutocompleteSelectComponent
                    id={`edit-channel`}
                    key={`key-channel`}
                    reference={SupportedReference.channel}
                    required={false}
                    information={""}
                    autocompleteDropDownStyle={{'width': '400px'}}
                    label={"Channel"}
                    onSelect={onChannelSelection}
                />
                <AsyncAutocompleteSelectComponent
                    id={`edit-store`}
                    key={`key-store`}
                    reference={SupportedReference.store}
                    required={false}
                    information={""}
                    autocompleteDropDownStyle={{'width': '400px'}}
                    label={"Stock Pool ID"}
                    onSelect={onStoreSelection}
                />
                <UIButton
                    text={wordingStockLocationRanking.action.save.title}
                    color="primary"
                    className={classes.saveAction}
                    onClick={saveRowPosition}
                    disabled={!rowPositionHasChange}
                />
            </div>
            {!tableData && (
                <div className={classes.waitingSelection}>
                    <p>Begin by choosing a Stock Pool</p>
                </div>
            )}
            {tableData && (
                <div style={{ width: '100%', height: '100%' }}>
                    <div id="myGrid"
                         style={{
                             height: '100%',
                             width: '100%',
                             minHeight: '330px',
                             textAlign: 'left',
                         }}
                         className="ag-theme-alpine"
                    >
                        <AgGridReact
                            modules={AllCommunityModules}
                            defaultColDef={{
                                sortable: false,
                                filter: false,
                            }}

                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={false}
                            animateRows={true}
                            onRowDragEnd={onDragEnd}
                            onGridReady={onGridReady}
                            rowData={rowData}
                        >
                            <AgGridColumn field="rankIfEqualQuantity" sortingOrder={['asc']} hide={true}/>
                            <AgGridColumn field="stockLocationCity" rowDrag={true} resizable={false} headerName='City' />
                            <AgGridColumn field="stockLocationCode" resizable={false} headerName='Code'/>
                            <AgGridColumn field="stockLocationCountryCode" resizable={false} headerName='Country'/>
                            <AgGridColumn field="stockLocationName" resizable={false} headerName='Name'/>
                            <AgGridColumn field="stockLocationType" resizable={false} headerName='Type'/>

                        </AgGridReact>
                    </div>
                </div>
            )}
        </Page>
    );

};
