import React, { KeyboardEvent, FormControl, useState }  from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TextField } from '@material-ui/core';
import { HeadCell } from 'graphics/tables/data/HeadCell';
import { TableRowData } from 'graphics/tables/data/TableRowData';
import { Order } from './operations/sort';
import { useStyles } from './styles/useStyles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';

interface EnhancedTableProps<T extends TableRowData> {
    classes: ReturnType<typeof useStyles>;
    numSelected: number;
    enableRowSelection: Boolean;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    onMultiCellsSearch?: (headCells: HeadCell<T>[]) => void;
    order: Order;
    orderBy: keyof T | undefined | string;
    headCells: HeadCell<T>[];
    deactiveSearch: boolean;
    rowCount: number;
}

export function DatatableHead<T extends TableRowData>(props: EnhancedTableProps<T>) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, onMultiCellsSearch, deactiveSearch } = props;
    const [cells, setCells] = useState<HeadCell<T>[]>(props.headCells);

    const createSortHandler = (property: keyof T) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    const updateSearchValueField = (fieldName: string) => ({ target: { value } }: FieldEventType) => {
            const newCells  = cells.map((headCell) => {

                if(headCell.label === fieldName) {
                    const updatedCell = {
                        ...headCell,
                        searchValue: value,
                    };
                    return updatedCell;
                }
                return headCell;
            });

            setCells(newCells);

    };

    React.useEffect(() => {
        if(cells.length != props.headCells.length) {
            setCells(props.headCells);
        }
    }, [props.headCells])

    const keyPress = (e: KeyboardEvent<FormControl>) => {

        const key = e.nativeEvent.key;
        if (key === 'Enter' && onMultiCellsSearch) {
            onMultiCellsSearch(cells);
        }

    };


    return (
        <TableHead>
            <TableRow>
                {!!props.enableRowSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{ 'aria-label': 'select all desserts' }}
                        />
                    </TableCell>
                )}
                {props.headCells.filter(propHeadCell => (propHeadCell.visible || propHeadCell.label === '')).map(propHeadCell => (

                    cells.filter(headCell => propHeadCell.label === headCell.label).map(headCell => (

                        <TableCell
                        key={headCell.id}
                        align={(headCell.type === "numeric")  ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.displayName}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                        { headCell.label && onMultiCellsSearch && !deactiveSearch && (
                                <>
                                    <br/>
                                    <TextField type="text" id="standard-start-adornment" value={headCell.searchValue} 
                                        onKeyDown={keyPress} 
                                        onChange={updateSearchValueField(headCell.label)}
                                            InputProps={{startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
                                        }}
                                    />
                                </>
                            
                        )}
                    </TableCell>

                    ))

                ))}
            </TableRow>
        </TableHead>
    );
}
